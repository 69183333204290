import myaxios from "../MyAxios";
const URL = myaxios.baseUrl();
const articleApi = {
    // 获取首页数据
    indexData(params) {
        // https: //www.bnsjzc.com/bountyCity/server/home
        let url = URL + "/server/home";
        return myaxios.get(url, params);
    },
    // 获取首页关注好友发布的视频
    followFriendVideos(params) {
        let url = URL + "/server/home/followArticlesAndVideosPostedByFriends";
        return myaxios.get(url, params);
    }
}
export default articleApi;