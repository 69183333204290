import myaxios from "../MyAxios";
const URL = myaxios.baseUrl();
// const URL = baseurl.BASEURL;

const UserApi = {
  // 用户登录
  userLogin(params) {
    let url = URL + "/user/login/login_auth";
    return myaxios.postJSON(url, params);
  },
  // 发送验证码
  sendMessage(params) {
    let url = URL + "/user/login/sendMessage";
    return myaxios.postJSON(url, params);
  },
  // 找回密码
  retrievePassword(params) {
    let url = URL + "/user/login/retrievePassword";
    return myaxios.postJSON(url, params);
  },
  // 获取用户资料
  userInfo(params) {
    let url = URL + "/user/user_information/userInfo";
    return myaxios.get(url, params);
  },
  // 获取我的用户资料
  myUserInfo(params) {
    let url = URL + "/user/user_information/myUserInfo";
    return myaxios.get(url, params);
  },
  // 修改保存用户信息
  updateUserInfo(params) {
    let url = URL + "/user/user/loginReginfo";
    return myaxios.postJSON(url, params);
  },
  // 上传图片到OSS
  uploadImage(params) {
    let url = URL + "/user/oss/uploadPictures";
    return myaxios.post(url, params);
  },
  // 设置密码
  setPassword(params) {
    let url = URL + "/user/login/setPassword";
    return myaxios.postJSON(url, params);
  },
  // 修改密码
  updataPassword(params) {
    let url = URL + "/user/login/changePassword";
    return myaxios.postJSON(url, params);
  },
  // 我的钱包余额
  wallet() {
    let url = URL + "/server/wallet/myBalance";
    return myaxios.post(url);
  },
  // 我的积分
  myPoints() {
    let url = URL + "/server/integral/myPoints";
    return myaxios.get(url);
  },
  // 我的好友
  myFriend(params) {
    let url = URL + "/user/followWithInterest/myFriends";
    return myaxios.get(url, params);
  },
  // 我的关注
  myAttention(params) {
    let url = URL + "/user/followWithInterest/myAttention";
    return myaxios.get(url, params);
  },
  // 关注我的
  myFans(params) {
    let url = URL + "/user/followWithInterest/followMe";
    return myaxios.get(url, params);
  },
  // 用户关注/取消关注
  attention(params) {
    let url = URL + "/user/followWithInterest/user_follow";
    return myaxios.postJSON(url, params);
  },
  // 获取用户详细信息
  userInfomation(params) {
    let url = URL + "/user/user_information";
    return myaxios.get(url, params);
  },
};

export default UserApi;