<template>
  <div class="content">
    <el-container style="height: 100vh">
      <el-header height="75px">
        <div class="menu">
          <div class="logo" style="height: 70px">
            <img src="../assets/logo.png" style="width: 40px; height: 40px" />
            <p class="title">赏金之城</p>
          </div>
          <el-menu
            router
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#333"
            text-color="#fff"
            active-text-color="#E3B861"
          >
            <el-menu-item index="/home/index">首页</el-menu-item>
            <el-menu-item index="/home/about"> 关于赏金 </el-menu-item>
            <el-menu-item index="/home/task-hall">任务大厅</el-menu-item>
            <el-menu-item index="/home/firm-insert">企业入驻</el-menu-item>
            <el-menu-item index="/home/phone-onload">下载赏金之城</el-menu-item>
            <el-menu-item index="/home/vip-center">会员中心</el-menu-item>
          </el-menu>
        </div>

        <div class="login" v-if="!isLogin" @click="visible">登录</div>
        <div class="login" v-else>
          <el-badge is-dot class="item" :hidden="noticeNum == 0">
            <div class="login" @click="mess">消息中心</div>
          </el-badge>

          <el-dropdown @command="handleCommand">
            <div
              style="
                color: #fff;
                overflow: hidden;
                width: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              <!-- <img src="" alt="" /> -->
              <div style="display: flex; align-items: center">
                <img
                  :src="user.avatar"
                  alt=""
                  style="
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 5px;
                  "
                />
                <p style="color: #fff; overflow: hidden; width: auto">
                  {{ user.nickname }}
                </p>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">个人中心</el-dropdown-item>
              <el-dropdown-item command="2">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-main>
          <!-- 二级路由占位符 -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <!-- 登录注册弹窗 -->
    <el-dialog
      :visible.sync="this.$store.state.dialogVisible"
      :before-close="handleClose"
      width="686px"
    >
      <div class="login-box">
        <div class="down-load">
          <div class="img-box">
            <img
              src="../assets/appma.jpg"
              style="width: 180px; height: 180px"
            />
          </div>
          <p>扫码下载手机APP</p>
        </div>
        <div class="login-main">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="账号登录" name="first">
              <div class="form-box">
                <input
                  type="text"
                  v-model="form.phone"
                  placeholder="输入登录账号"
                />
                <input
                  type="password"
                  v-model="form.password"
                  placeholder="输入登录密码"
                />
                <el-button type="primary" class="btn" @click="userlogin"
                  >登录</el-button
                >
                <p>
                  登录即同意<a
                    href="https://www.bnsjzc.com/privacyPolicy/"
                    target="blank"
                    >《隐私政策》</a
                  >和<a href="https://www.bnsjzc.com/user/" target="blank"
                    >《用户服务协议》</a
                  >
                </p>

                <p @click="forgetpwd" style="margin-top: 10px; color: #f56712">
                  忘记密码
                </p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="注册/短信登录" name="second">
              <div class="form-box">
                <input
                  type="text"
                  v-model="form.phone"
                  placeholder="输入登录手机号"
                />
                <div class="pwd">
                  <input
                    type="text"
                    v-model="form.code"
                    placeholder="输入验证码"
                  />
                  <el-button
                    :disabled="isClick"
                    id="sendCode"
                    type="primary"
                    class="btn"
                    @click="countdowm"
                    >{{ content }}</el-button
                  >
                </div>
                <el-button type="primary" class="btn" @click="userlogin"
                  >登录</el-button
                >
                <p>
                  登录即同意<a
                    href="https://www.bnsjzc.com/privacyPolicy/"
                    target="blank"
                    >《隐私政策》</a
                  >和<a href="https://www.bnsjzc.com/user/" target="blank"
                    >《用户服务协议》</a
                  >
                </p>
                <p style="margin-top: 10px; color: #f56712">
                  第一次登录默认注册
                </p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
    <Password
      @tyonke="tyonkeChange"
      :centerDialogVisibleSecond="dialogForgetpwd"
    ></Password>
  </div>
</template>

<script>
import Password from "../components/password";
import { mapState, mapActions } from "vuex";
import store from "@/store";
import router from "@/router";
import httpApi from "@/http/index";

export default {
  components: {
    Password,
  },
  data() {
    return {
      isLogin: false,
      dialogVisible: false,
      activeName: "first",
      form: {
        phone: "",
        password: "",
        code: "",
      },
      user: {}, //用户信息
      // 验证码数据
      content: "发送验证码",
      canClick: true,
      refreshData: null,
      dialogForgetpwd: false, //忘记密码弹窗
      noticeNum: 0,
      isClick: false,
    };
  },
  onLoad() {},
  // beforeCreate() {
  //   this.youkelogin();
  // },
  mounted() {
    // 判断是否处于登录状态
    let userLogin = store.state.user;
    // console.log(token);
    if (userLogin) {
      this.isLogin = true;
      this.getMyUserInfo();
    } else {
      this.youkelogin();
    }
    // 获取未读消息数
    this.getNoticeNum();
  },
  methods: {
    // 获取未读消息数
    getNoticeNum() {
      let params = {
        status: 9,
      };
      httpApi.NoticeApi.getUnreadMessages(params).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          this.noticeNum = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 添加vuex中定义的actions方法（login方法）的映射
    ...mapActions(["login"]),
    // 登录
    userlogin() {
      let params = {
        phone: this.form.phone,
        password: this.form.password,
        code: this.form.code,
      };
      console.log("登录参数", params);
      this.login(params);
      setTimeout(() => {
        let userLogin = store.state.user;
        if (userLogin) {
          // console.log("登录成功");
          this.isLogin = true;
          store.commit("dialogVisible", false);
          this.form = "";
          this.getMyUserInfo();
        } else {
          // console.log("登录失败");
        }
      }, 1000);
    },
    // 游客登陆
    ...mapActions(["ykLogin"]),
    youkelogin() {
      let params = {
        phone: "",
        password: "",
      };
      this.ykLogin(params);
      setTimeout(() => {
        let token = store.state.token;
        if (token) {
          console.log("游客登录成功");
        } else {
          console.log("游客登录失败");
        }
      }, 1000);
    },
    // 获取我的用户资料
    getMyUserInfo() {
      let params = {
        userId: Number(store.state.user.id),
      };
      httpApi.UserApi.myUserInfo(params).then((res) => {
        console.log("用户资料", res.data.data);
        if (res.data.code == 200) {
          this.user = res.data.data;
          store.commit("getUserData", res.data.data);
          localStorage.setItem("MyInfo", JSON.stringify(this.user));
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 忘记密码
    forgetpwd() {
      this.dialogVisible = false;
      this.dialogForgetpwd = true;
    },
    tyonkeChange(val) {
      this.dialogForgetpwd = val;
    },
    //发送手机验证码
    countdowm() {
      let params = {
        phone: this.form.phone,
      };
      httpApi.UserApi.sendMessage(params).then((res) => {
        console.log("验证码", res);
        if (res.data.code == 200) {
          let totalTime = 60;
          if (!this.canClick) return; //节流
          this.canClick = false;
          this.content = totalTime + "s重新发送";
          this.isClick = true;
          // $("#sendCode button").attr("disabled", true);

          this.refreshData = window.setInterval(() => {
            totalTime--;
            this.content = totalTime + "s重新发送";
            console.log(this.content);
            if (totalTime < 0) {
              //当倒计时小于0时清除定时器
              window.clearInterval(this.refreshData); //清除定时器
              this.content = "重新发送";
              totalTime = 60;
              this.isClick = false;

              // $("#sendCode button").attr("disabled", false);
              this.canClick = true; //这里重新开启
            }
          }, 1000);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 下拉菜单选项
    handleCommand(command) {
      if (command == 1) {
        this.$router.push("/home/my-center/user-info");
      } else if (command == 2) {
        this.$confirm("您确定退出登录吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "您已退出登录!",
            });
            store.commit("updateUser", null);
            store.commit("saveToken", null);
            store.commit("getUserData", {});
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
              let token = store.state.token;
              if (token) {
                console.log(token);
                this.isLogin = true;
              } else {
                this.isLogin = false;
                router.replace("/home/index");
              }
            }, 500);
          })
          .catch(() => {});
      }
    },
    // 切换标签页
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 消息中心
    mess() {
      this.$router.push("/home/mess-center/system");
    },
    visible() {
      this.$store.commit("dialogVisible", true);
    },
    handleClose() {
      this.$store.commit("dialogVisible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-width: 960px;
}
.el-header {
  // background-color: #fff;
  background-color: #333;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  line-height: 120px;
  i {
    font-size: 1.3em;
  }
}
.el-menu-demo {
  height: 75px;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo {
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}
.login {
  color: #fff;
  padding: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.title {
  // color: #333;
  color: #fff;
  font-size: 14px;
  margin: 0 20px;
}
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  height: 75px;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  line-height: 75px;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #409eff;
}

.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}
.aside::-webkit-scrollbar {
  display: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
::v-deep .el-tabs__active-bar {
  background-color: #f56712;
}
::v-deep .el-tabs__item.is-active {
  color: #f56712;
}
::v-deep .el-tabs__item :hover {
  color: #f56712;
}
::v-deep .el-dropdown-selfdefine {
  height: 75px;
  line-height: 75px;
  font-size: 16px;
}
::v-deep .el-badge__content.is-fixed.is-dot {
  top: 22px;
  right: 20px;
}
// 登录弹窗样式
.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 50px 0;
  .down-load {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login-main {
    margin-left: 50px;
    width: 48%;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    input {
      padding: 15px;
      border-radius: 5;
      border: none;
      background-color: #eee;
      margin: 15px 0;
    }
    .pwd {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: #eee;
      padding: 0;
      margin: 15px 0;
      input {
        margin: 0;
      }
      .btn {
        margin: 0;
      }
    }
    .btn {
      background-color: #f56712;
      border: none;
      margin: 15px 0;
    }
    p {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
