import myaxios from "../MyAxios";
const URL = myaxios.baseUrl();
// const URL = baseurl.BASEURL;

const VipApi = {
  // 获取会员充值规则
  getMemberInformation() {
    let url = URL + "/server/member/getMemberInformation";
    return myaxios.get(url);
  },
  // 企业信息录入
  enterpriseSettlement(params) {
    let url = URL + "/server/enterprise/enterpriseSettlement";
    return myaxios.postJSON(url, params);
  },
  //  获取企业入驻规则
  obtainEnterpriseEntryRules() {
    let url = URL + "/server/enterprise/obtainEnterpriseEntryRules";
    return myaxios.get(url);
  },
  //  我申请的企业信息
  myBusinessInformation() {
    let url = URL + "/server/enterprise/myBusinessInformation";
    return myaxios.post(url);
  },
};

export default VipApi;
