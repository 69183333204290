<template>
  <div class="content">
    <el-container>
      <el-aside width="240px">
        <!-- 菜单 -->
        <div class="menu">
          <el-menu
            router
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            active-text-color="#f56712"
          >
            <el-menu-item index="/home/mess-center/system">
              <span slot="title">
                系统通知
                <el-badge
                  is-dot
                  style="left: 10px; top: -3px"
                  :hidden="system == 0"
                >
                </el-badge>
              </span>
            </el-menu-item>
            <el-menu-item index="/home/mess-center/task">
              <span slot="title">
                任务通知
                <el-badge
                  is-dot
                  style="left: 10px; top: -3px"
                  :hidden="task == 0"
                >
                </el-badge>
              </span>
            </el-menu-item>
            <el-menu-item index="/home/mess-center/friend">
              <span slot="title">
                好友通知
                <el-badge
                  is-dot
                  style="left: 10px; top: -3px"
                  :hidden="friend == 0"
                >
                </el-badge>
              </span>
            </el-menu-item>

            <!-- <el-menu-item index="/home/mess-center/pay">
              <span slot="title">支付通知</span>
            </el-menu-item> -->
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <!-- 二级路由占位符 -->
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import httpApi from "@/http/index";

export default {
  data() {
    return {
      system: 0,
      task: 0,
      friend: 0,
    };
  },
  mounted() {
    // 获取未读系统消息数
    this.getSystem();
    // 获取未读任务消息数
    this.getTask();
    // 获取未读好友消息数
    this.getFriend();
  },
  methods: {
    getSystem() {
      let params = {
        status: 6,
      };
      httpApi.NoticeApi.getUnreadMessages(params).then((res) => {
        if (res.data.code == 200) {
          this.system = res.data.data;
          console.log(this.system);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getTask() {
      let params = {
        status: 5,
      };
      httpApi.NoticeApi.getUnreadMessages(params).then((res) => {
        if (res.data.code == 200) {
          this.task = res.data.data;
          console.log(this.task);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getFriend() {
      let params = {
        status: 3,
      };
      httpApi.NoticeApi.getUnreadMessages(params).then((res) => {
        if (res.data.code == 200) {
          this.friend = res.data.data;
          console.log(this.friend);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 50px 10%;
  background-color: #f5f5f5;
}

.el-main {
  padding: 0;
  color: #333;
  margin-left: 20px;
  box-sizing: border-box;
  // display: flex;
}

.el-aside {
  // background-color: #d3dce6;
  color: #333;
  height: 100vh;
}
.user-info {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  .avatar {
    position: relative;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    p {
      padding: 2px 0;
      width: 30px;
      border-radius: 5px;
      background-color: #f56712;
      color: #fff;
      font-size: 10px;
      position: absolute;
      bottom: 0px;
      left: 15px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
  }
}
.menu {
  //   margin-top: 20px;
}
::v-deep .el-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
