// src/http/index.js
import UserApi from "./apis/userApi";
import TaskApi from "./apis/taskApi";
import NoticeApi from "./apis/noticeApi";
import VipApi from "./apis/vipApi";
import PayApi from "./apis/payApi";
import articleApi from "./apis/index_article";
const httpApi = {
  // 封装http接口
  UserApi,
  TaskApi,
  NoticeApi,
  VipApi,
  PayApi,
  articleApi,
};

export default httpApi;