import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.directive("title", {
  inserted: function (el, binding) {
    console.log(el);
    document.title = el.dataset.title
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')