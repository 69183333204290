import myaxios from "../MyAxios";
const URL = myaxios.baseUrl();
// const URL = baseurl.BASEURL;

const PayApi = {
  // 钱包明细
  walletDetails(params) {
    let url = URL + "/server/wallet/walletDetails";
    return myaxios.post(url, params);
  },
  // 积分兑换余额
  exchangeBalance(params) {
    let url = URL + "/server/integral/exchangeBalanceOfPoints";
    return myaxios.get(url, params);
  },
  // 绑定支付宝账号
  bindAlipayAccount(params) {
    let url = URL + "/user/user/bindAlipayAccount";
    return myaxios.post(url, params);
  },
  // 支付
  aliPay(params) {
    let url = URL + "/pay/alipay/webPay";
    return myaxios.postJSON(url, params);
  },
  // 微信支付
  wxpay(params) {
    let url = URL + "/pay/wxPay/wxWebPay";
    return myaxios.postJSON(url, params);
  }
};

export default PayApi;