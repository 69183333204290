<template>
  <el-dialog
    title="忘记密码"
    :visible.sync="centerDialogVisibleSecond"
    width="680px"
    :before-close="handleClose"
  >
    <el-form
      label-width="120px"
      ref="forgetpwdform"
      :model="forgetpwdform"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col :span="18">
          <el-form-item prop="phone" label="手机号：">
            <el-input v-model="forgetpwdform.phone" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button @click="sendcodeMsg" :disabled="disabled">{{
            sendcode
          }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item prop="code" label="验证码：">
            <el-input v-model="forgetpwdform.code"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="password" label="新密码">
        <el-input type="password" v-model="forgetpwdform.password"></el-input>
      </el-form-item>
      <el-form-item prop="passwordAgain" label="确认密码">
        <el-input
          type="password"
          v-model="forgetpwdform.passwordAgain"
        ></el-input>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button type="default" @click="handleClose">取消</el-button>
      <el-button
        type="primary"
        @click="handleSubmit"
        style="background-color: #f56712; border: none"
        >提交</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import qs from "qs";
import httpApi from "@/http/index";

export default {
  name: "forgetpwd",
  props: ["centerDialogVisibleSecond"],
  data() {
    return {
      dialogVisibleSecond: this.centerDialogVisibleSecond,
      getCode: "",
      sendcode: "发送验证码",
      disabled: false,
      forgetpwdform: {
        phone: "",
        code: "",
        password: "",
        passwordAgain: "",
      },
      rules: {
        phone: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
        code: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "新密码不能为空",
            trigger: "blur",
          },
        ],
        passwordAgain: [
          {
            required: true,
            message: "重置密码不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //手机号正则判断
    judgePhone() {
      const reg =
        /^((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8}$/;
      //var url="/nptOfficialWebsite/apply/sendSms?mobile="+this.ruleForm.phone;
      if (this.forgetpwdform.phone == "") {
        this.$message("请输入手机号码");
        return false;
      } else if (!reg.test(this.forgetpwdform.phone)) {
        this.$message("手机号格式不正确");
        return false;
      } else {
        return true;
      }
    },
    //发送验证码
    sendcodeMsg() {
      let params = {
        phone: this.forgetpwdform.phone,
      };
      httpApi.UserApi.sendMessage(params).then((res) => {
        console.log("验证码", res);
        if (res.data.code == 200) {
          let _this = this;
          if (_this.judgePhone()) {
            var num = 60;
            var timer = setInterval(function () {
              num--;
              _this.disabled = true;
              _this.sendcode = num + "秒后重新获取";
              if (num === 0) {
                _this.sendcode = "获取验证码";
                _this.disabled = false;
                clearInterval(timer);
              }
            }, 1000);
          }
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //关闭页面
    handleClose() {
      // this.centerDialogVisibleSecond = false;
      this.dialogVisibleSecond = false;

      this.$emit("tyonke", this.dialogVisibleSecond);
    },

    //表单提交
    handleSubmit() {
      let _this = this;
      _this.$refs["forgetpwdform"].validate((valid) => {
        if (valid) {
          let params = {
            phone: this.forgetpwdform.phone,
            code: this.forgetpwdform.code,
            password: this.forgetpwdform.password,
            passwordAgain: this.forgetpwdform.passwordAgain,
          };
          httpApi.UserApi.retrievePassword(params).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功",
                duration: 1000,
              });
            } else {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1000,
              });
            }
          });
        } else {
          return;
        }
      });
    },
  },
  watch: {
    centerDialogVisibleSecond(newValue, oldValue) {
      // this.centerDialogVisibleSecond = newValue;
      this.dialogVisibleSecond = newValue;
    },
  },
};
</script>

<style scoped>
::v-deep .el-form {
  width: 90%;
}
::v-deep .el-input .el-input__inner {
  border: none;
  background-color: #f5f5f5;
  font-size: 14px;
}
::v-deep .el-textarea__inner {
  border: none;
  background-color: #f5f5f5;
  resize: none;
  font-size: 14px;
}
</style>
