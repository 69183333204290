import axios from "axios";
// qs模块可以将对象转换为查询字符串
import qs from "qs";
import store from "@/store";

// 创建axios实例
const instance = axios.create();
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 对config进行操作
  let token = store.state.token;
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

const myaxios = {
  // 开发环境
  baseUrl() {
    // return "http://xiaoqi.l2.ttut.cc";
    return "https://www.bnsjzc.com/bountyCity";
  },
  /**
   * 基于 axios 发送 get 请求，返回 Promise 对象。
   * @param {String} url 请求资源路径
   * @param {Object} params 请求参数
   */
  get(url, params) {
    return instance({
      url,
      params,
      method: "GET",
    });
  },
  getJSON(url, params) {
    return instance({
      url,
      data: params,
      method: "GET",
    });
  },
  /**
   * 基于axios发送post请求
   * @param {String} url 请求资源路径
   * @param {Object} params 请求参数  {page:1, pagesize:20}
   */
  post(url, params) {
    // qs模块，可以将params转换成查询字符串
    // {page:1, pagesize:20}  ==>  page=1&pagesize=20
    return instance({
      url,
      data: qs.stringify(params),
      method: "POST",
    });
  },
  /**
   * 基于axios发送post请求
   * @param {String} url 请求资源路径
   * @param {Object} params 请求参数  {page:1, pagesize:20}
   */
  postJSON(url, params) {
    // qs模块，可以将params转换成查询字符串
    // {page:1, pagesize:20}  ==>  page=1&pagesize=20
    return instance({
      url,
      data: params,
      method: "POST",
    });
  },
  /**
   * 基于axios发送put请求
   * @param {String} url 请求资源路径
   * @param {Object} params 请求参数
   */
  put(url, params) {
    return instance({
      url,
      data: qs.stringify(params),
      method: "PUT",
    });
  },
  /**
   * 基于axios发送delete请求
   * @param {String} url 请求资源路径
   * @param {Object} params 请求参数
   */
  delete(url, params) {
    return instance({
      url,
      params,
      method: "DELETE"
    });
  },
};

export default myaxios;