import { render, staticRenderFns } from "./myCenter.vue?vue&type=template&id=41011525&scoped=true"
import script from "./myCenter.vue?vue&type=script&lang=js"
export * from "./myCenter.vue?vue&type=script&lang=js"
import style0 from "./myCenter.vue?vue&type=style&index=0&id=41011525&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41011525",
  null
  
)

export default component.exports