import myaxios from "../MyAxios";
const URL = myaxios.baseUrl();
// const URL = baseurl.BASEURL;

const TaskApi = {
  // 我的任务
  myTask(params) {
    let url = URL + "/server/bountyTask/myTasks";
    return myaxios.get(url, params);
  },
  // 我接取的任务
  myReceivedTasks(params) {
    let url = URL + "/server/bountyTask/receivedTasks";
    return myaxios.get(url, params);
  },
  // 我完成的任务
  myCompletedTasks(params) {
    let url = URL + "/server/bountyTask/completedTasks";
    return myaxios.get(url, params);
  },
  // 我放弃的任务
  myAbandonedTasks(params) {
    let url = URL + "/server/bountyTask/abandonedTasks";
    return myaxios.get(url, params);
  },
  // 查看任务详情
  taskDetails(params) {
    let url = URL + "/server/bountyTask/taskDetails";
    return myaxios.get(url, params);
  },
  // 用户发布的任务
  userTask(params) {
    let url = URL + "/server/bountyTask/userTasks";
    return myaxios.get(url, params);
  },
  // 任务大厅
  taskHall(params) {
    let url = URL + "/server/bountyTask/taskHall";
    return myaxios.get(url, params);
  },
  // 接取任务
  receivingTask(params) {
    let url = URL + "/server/bountyTask/receivingTask";
    return myaxios.post(url, params);
  },
  // 撤销任务
  revokeTask(params) {
    let url = URL + "/server/bountyTask/revokeTask";
    return myaxios.put(url, params);
  },
  // 放弃任务
  abortMission(params) {
    let url = URL + "/server/bountyTask/abortMission";
    return myaxios.put(url, params);
  },
  // 提交任务
  completeTheTask(params) {
    let url = URL + "/server/bountyTask/completeTheTask";
    return myaxios.put(url, params);
  },
  // 确认完成任务
  confirmCompletionOfTask(params) {
    let url = URL + "/server/bountyTask/confirmCompletionOfTask";
    return myaxios.put(url, params);
  },
  // 举报
  report(params) {
    let url = URL + "/server/report";
    return myaxios.postJSON(url, params);
  },
  // 删除任务
  deleteTask(params) {
    let url = URL + "/server/bountyTask/deleteTask";
    return myaxios.delete(url, params);
  },
  // 发布任务
  publishTask(params) {
    let url = URL + "/server/bountyTask/publishTask";
    return myaxios.postJSON(url, params);
  },
  // 搜索任务
  search(params) {
    let url = URL + "/search/task/search";
    return myaxios.postJSON(url, params);
  },
  // 支付尾款
  paymentFinal(params) {
    let url = URL + "/server/bountyTask/obtainTheFinalPaymentForTheTask";
    return myaxios.post(url, params);
  },
  // 关注好友发布的任务
  friendsPublish(params) {
    let url = URL + "/server/bountyTask/followTasksPostedByFriends"
    return myaxios.get(url, params);
  },
  // 获取文章详情
  ArticleDetail(params) {
    let url = URL + "/server/news/articleDetails"
    return myaxios.get(url, params);
  },
  // 获取视频详情
  videoDetail(params) {
    let url = URL + "/server/video/videoDetails"
    return myaxios.get(url, params);
  },
  // 获取寻人列表
  missingPerson(params) {
    let url = URL + "/server/missingPersonPet/missingPersonList"
    return myaxios.get(url, params);
  },
  // 获取公益详情
  getpublicDetails(params) {
    let url = URL + "/server/missingPersonPet/details";
    return myaxios.get(url, params);
  },

};

export default TaskApi;