<template>
  <div class="content">
    <el-container>
      <el-aside width="240px">
        <!-- 用户信息卡片 -->
        <div class="user-info">
          <div class="avatar">
            <img :src="user.avatar" alt="" />
            <img
              v-if="user.isCompany"
              class="firm"
              src="../../assets/user/firm.png"
            />
          </div>
          <div class="info">
            <div class="name">
              <div
                style="
                  font-weight: bold;
                  font-size: 14px;
                  overflow: hidden;
                  width: 100px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ user.nickname }}
              </div>
              <!-- 会员等级 -->
              <img
                v-if="user.memberType == 1"
                src="../../assets/user/vip1.png"
              />
              <img
                v-if="user.memberType == 2"
                src="../../assets/user/vip2.png"
              />
              <img
                v-if="user.memberType == 3"
                src="../../assets/user/vip3.png"
              />
              <img
                v-if="user.memberType == 4"
                src="../../assets/user/vip4.png"
              />
            </div>
            <p
              v-if="user.autograph"
              style="font-size: 12px; color: grey; margin-top: 5px"
            >
              {{ user.autograph }}
            </p>
            <p v-else style="font-size: 12px; color: grey; margin-top: 5px">
              暂无签名
            </p>
          </div>
        </div>
        <!-- 菜单 -->
        <div class="menu">
          <el-menu
            router
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            active-text-color="#f56712"
          >
            <el-menu-item index="/home/my-center/user-info">
              <i class="el-icon-user-solid"></i>
              <span slot="title">个人资料</span>
            </el-menu-item>
            <el-menu-item index="/home/my-center/authritic">
              <i class="el-icon-success"></i>
              <span slot="title">实名认证</span>
            </el-menu-item>
            <el-menu-item index="/home/my-center/password">
              <i class="el-icon-s-cooperation"></i>
              <span slot="title">我的密码</span>
            </el-menu-item>
            <el-menu-item index="/home/my-center/taskCenter">
              <i class="el-icon-s-order"></i>
              <span slot="title">任务管理</span>
            </el-menu-item>
            <el-menu-item index="/home/my-center/wallet">
              <i class="el-icon-s-finance"></i>
              <span slot="title">我的资产</span>
            </el-menu-item>
            <el-menu-item index="/home/my-center/friend">
              <i class="el-icon-s-unfold"></i>
              <span slot="title">好友列表</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <!-- 二级路由占位符 -->
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import httpApi from "@/http/index";
export default {
  data() {
    return {
      user: {
        avatar: require("../../assets/user/avatar.png"),
        nickname: "未登录",
      }, //用户资料
    };
  },
  mounted() {
    // 获取用户信息
    this.user = JSON.parse(localStorage.getItem("MyInfo"));
    // this.getMyUserInfo();
  },
  methods: {
    // 获取我的用户资料
    getMyUserInfo() {
      httpApi.UserApi.myUserInfo().then((res) => {
        console.log("用户资料", res.data);
        if (res.data.code == 200) {
          this.user = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 50px 10%;
  background-color: #f5f5f5;
}

.el-main {
  padding: 0;
  color: #333;
  margin-left: 20px;
  box-sizing: border-box;
  // display: flex;
}

.el-aside {
  // background-color: #d3dce6;
  color: #333;
  height: 100vh;
}
.user-info {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  .avatar {
    position: relative;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .firm {
      width: 50px;
      height: 50px;
      position: absolute;
      bottom: -20px;
      left: 5px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
    .name {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;
      img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
}
.menu {
  margin-top: 20px;
}
::v-deep .el-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
