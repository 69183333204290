import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import myCenter from "../views/myCenter/myCenter.vue";
import messCenter from "../views/messCenter/messCenter.vue";
import {
  mapState,
  mapActions
} from "vuex";
import store from "@/store";
import httpApi from "@/http/index";
Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "/home/index",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [{
        path: "index",
        name: "/home/index",
        component: () => import("../views/index_article.vue"),
      },
      {
        path: "about",
        name: "/home/about",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "test",
        name: "/home/test",
        component: () => import("../views/test.vue"),
      },
      {
        path: "task-hall",
        name: "/home/task-hall",
        component: () => import("../views/TaskHall.vue"),
      },
      {
        path: "firm-insert",
        name: "/home/firm-insert",
        component: () => import("../views/FirmInsert.vue"),
      },
      {
        path: "phone-onload",
        name: "/home/phone-onload",
        component: () => import("../views/PhoneOnload.vue"),
      },
      {
        path: "vip-center",
        name: "/home/vip-center",
        component: () => import("../views/VipCenter.vue"),
      },
      {
        path: "task-publish",
        name: "/home/task-publish",
        component: () => import("../views/TaskPublish.vue"),
      },
      {
        path: "task-detail",
        name: "/home/task-detail",
        component: () => import("../views/TaskDetail.vue"),
      },
      {
        path: "taskboss-detail",
        name: "/home/taskboss-detail",
        component: () => import("../views/TaskBossDetail.vue"),
      },
      {
        path: "friend-space",
        name: "/home/friend-space",
        component: () => import("../views/FriendSpace.vue"),
      },
      {
        path: "report",
        name: "/home/report",
        component: () => import("../views/Report.vue"),
      },
      {
        path: "publicDetail",
        name: "/home/publicDetail",
        component: () => import("../views/public_detail"),
      },
      {
        path: "my-center",
        name: "/home/my-center",
        component: myCenter,
        children: [{
            path: "user-info",
            name: "/home/my-center/user-info",
            component: () => import("../views/myCenter/userInfo.vue"),
          },
          {
            path: "wallet",
            name: "/home/my-center/wallet",
            component: () => import("../views/myCenter/wallet.vue"),
          },
          {
            path: "taskCenter",
            name: "/home/my-center/taskCenter",
            component: () => import("../views/myCenter/taskCenter.vue"),
          },
          {
            path: "password",
            name: "/home/my-center/password",
            component: () => import("../views/myCenter/password.vue"),
          },
          {
            path: "authritic",
            name: "/home/my-center/authritic",
            component: () => import("../views/myCenter/authritic.vue"),
          },
          {
            path: "friend",
            name: "/home/my-center/friend",
            component: () => import("../views/myCenter/friend.vue"),
          },
        ],
      },
      {
        path: "mess-center",
        name: "/home/mess-center",
        component: messCenter,
        children: [{
            path: "system",
            name: "/home/mess-center/system",
            component: () => import("../views/messCenter/system.vue"),
          },
          {
            path: "task",
            name: "/home/mess-center/task",
            component: () => import("../views/messCenter/task.vue"),
          },
          {
            path: "pay",
            name: "/home/mess-center/pay",
            component: () => import("../views/messCenter/pay.vue"),
          },
          {
            path: "friend",
            name: "/home/mess-center/friend",
            component: () => import("../views/messCenter/friend.vue"),
          },
        ],
      },
      {
        path: "task_article",
        name: "/home/task_article",
        component: () => import("../views/task_article"),
        meta: {
          title: ""
        }
      },
      {
        path: "task_video",
        name: "/home/task_video",
        component: () => import("../views/task_video"),
      }
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return {
  //     x: 0,
  //     y: 0
  //   };
  // },
});
router.beforeEach((to, from, next) => {
  if (store.state.token) {
    next()
  } else {
    let params = {
      phone: "",
      password: "",
    };
    httpApi.UserApi.userLogin(params).then((res) => {
      if (res.data.code == 200) {
        store.commit("saveToken", res.data.data.token);
        next()
      }
    });
  }
  // if (to.meta.title) {
  //   console.log(to.meta)
  //   document.title = to.meta.title;
  // }
  // next();
})
export default router;