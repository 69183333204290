import myaxios from "../MyAxios";
const URL = myaxios.baseUrl();
// const URL = baseurl.BASEURL;

const NoticeApi = {
  // 获取平台消息
  getPlatformMessages(params) {
    let url = URL + "/server/platformMessageNotification/getPlatformMessages";
    return myaxios.get(url, params);
  },
  //  一键已读
  oneClickRead(params) {
    let url = URL + "/server/platformMessageNotification/oneClickRead";
    return myaxios.get(url, params);
  },
  //  获取未读消息数
  getUnreadMessages(params) {
    let url = URL + "/server/platformMessageNotification/getUnreadMessages";
    return myaxios.get(url, params);
  },
  //  批量删除消息
  batchDeleteMessages(params) {
    let url = URL + "/server/platformMessageNotification/batchDeleteMessages";
    return myaxios.get(url, params);
  },
};

export default NoticeApi;
